import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import Confirmation from "./Confirmation";
import { CssBaseline } from "@mui/material";
import { Toaster } from "react-hot-toast";
import Confirmed from "./Confirmed";

const root = ReactDOM.createRoot(document.getElementById("root")!);

// Watch mode for esbuild
new EventSource("/esbuild").addEventListener("change", () => location.reload());

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/reset",
    element: <ResetPassword />,
  },
  {
    path: "/confirmation",
    element: <Confirmation />,
  },
  {
    path: "/confirmed",
    element: <Confirmed />,
  },
]);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          // Default options for specific types
          success: {
            style: {
              background: "#414061",
            },
          },
          error: {
            style: {
              background: "#4f1919",
            },
          },
        }}
      />
    </ThemeProvider>
  </React.StrictMode>
);
