import React from 'react';
import { Box, Button, Typography } from "@mui/material";

export default function Map() {

    return (
        
        <img src="map.png" style={{width: '100%', filter: 'brightness(0.3)', minHeight: '500px', objectFit: 'cover'}}/>
        
    )
}