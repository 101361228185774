import React from 'react';
import { Box, Button, Typography } from "@mui/material";

export interface CollectionChipProps{
    text?: string;
}

export default function CollectionChip(props: CollectionChipProps){

    return (

        <Box sx={{border: '3px solid #7B78FF', whiteSpace: 'nowrap', pointerEvents: 'none'}} px={2} py={0.6} borderRadius={100}>
            <Typography sx={{textTransform: 'uppercase', color: '#7B78FF'}}>
                {props.text ? props.text : "Test string"}
            </Typography>
        </Box>
    )
}