import { ArrowCircleDown, CheckCircle } from "@mui/icons-material";
import {
    Box,
    Button,
    Container,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { FC, PropsWithChildren } from "react";

const ConfirmationBox: FC<PropsWithChildren> = ({ children }) => {
    const mobileMode = useMediaQuery("(max-width:600px)");
    const mediumMode = useMediaQuery("(max-width:900px)");
    return (
        <Box
            minHeight="100vh"
            display="flex"
            alignItems="center"
            sx={{
                width: "100%",
                overflow: "hidden",
                position: "relative",
            }}
        >
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: -10,
                    width: mobileMode ? "600px" : "1000px",
                    height: mobileMode ? "600px" : "1000px",
                    transform: "translate(+20%, -20%)",
                    background:
                        "radial-gradient(circle, rgba(59,56,206,0.7) 0%, rgba(0,0,0,0) 70%)",
                }}
            />
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: -10,
                    opacity: mediumMode ? 0 : 1,
                    width: "45vw",
                    height: "45vw",
                    transform: "translate(-20%, -30%)",
                    background:
                        "radial-gradient(circle, rgba(81,196,221,0.6) 0%, rgba(38,36,163,0.3) 40%, rgba(0,0,0,0)60%)",
                }}
            />
            <Container
                maxWidth="sm"
                sx={{
                    zIndex: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Typography
                    variant="h4"
                    mb={4}
                    display="flex"
                    gap={"20px"}
                    alignItems={"center"}
                >
                    {children} <CheckCircle />
                </Typography>
                <Button
                    variant="outlined"
                    startIcon={<ArrowCircleDown />}
                    sx={{ paddingX: 3 }}
                    onClick={() => {
                        window.location.replace("vrims-app://app");
                    }}
                >
                    Go the app!
                </Button>
            </Container>
        </Box>
    );
};

export default ConfirmationBox;
