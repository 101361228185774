import React from 'react';
import { Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";

export default function CtaCard() {
    const theme = useTheme();

    const mobileMode = useMediaQuery('(max-width:500px)');
    const smallMode = useMediaQuery('(max-width:850px)');

    return (
        <Box
            width="100%" p={mobileMode ? 4 : 8} sx={{ bgcolor: theme.palette.primary.main }}
            borderRadius={6} display="flex" position="relative"
            mt={smallMode ? 22 : undefined}
        >
            <Box width={smallMode ? '100%' : '50%'} pt={smallMode ? 8 : undefined}>
                <Box sx={{ position: "relative", width: '100%', display: smallMode ? 'block' : 'none' }}>
                    <img src="vrims-phone-video.png" style={{ position: "absolute", bottom: 0, left: '50%', width: mobileMode ? '100%' : '400px', minWidth: '250px', transform: 'translate(-50%, 10%)' }} />
                </Box>
                <Typography variant='h2'>
                    View in VR and on mobile devices
                </Typography>
                <Typography mt={2}>
                    All content is easily accessible through the mobile app, allowing you to
                </Typography>
                <Button variant="outlined" sx={{ paddingX: 3, mt: smallMode ? 2 : 4 }}>
                    Get the app
                </Button>
            </Box>
            <Box sx={{ position: "relative", width: '50%', display: smallMode ? 'none' : 'block' }}>
                <img src="vrims-phone-video.png" style={{ position: "absolute", bottom: 0, right: 0, width: '100%', transform: 'translateY(10%)' }} />
            </Box>
        </Box>
    )
}