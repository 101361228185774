import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArrowCircleDown, CheckCircle } from "@mui/icons-material";
import ConfirmationBox from "./components/ConfirmationBox";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const [confirmationText, setConfirmationText] = useState("Email confirmed");

    const getConfirmation = async () => {
        try {
            const response = await fetch(
                `${process.env.API_HOST}/api/auth/email-confirmation?confirmation=${code}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const jsonResponse = await response.json();

            if (jsonResponse?.error) {
                throw jsonResponse?.error;
            }
        } catch (error) {
            // @ts-ignore
            if (error?.status !== 400) {
                // @ts-ignore
                Toast.error(error?.message);
                return;
            }
            setConfirmationText("Email already confirmed");
        }
    };

    useEffect(() => {
        if (!code) {
            navigate("/");
            return;
        }
        getConfirmation();
    }, []);

    return <ConfirmationBox>{confirmationText}</ConfirmationBox>;
};

export default ResetPassword;
