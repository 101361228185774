import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

export interface FeatureTileProps {
    title?: string;
    text?: string;
    variant: 1 | 2
}

export default function FeatureTile(props: FeatureTileProps) {

    const mobileMode = useMediaQuery('(max-width:600px)');

    return (
        <Box borderRadius={6} sx={{ aspectRatio: 1.6, overflow:'clip', backgroundImage: `url(vrims-info-tile${props.variant}.png)`, backgroundSize: 'cover' }} width={mobileMode ? '100%' : '50%'} >
            <Box width="100%" height="100%" p={'5%'} sx={{display: 'flex', alignItems: 'flex-end', background: 'linear-gradient(180deg, #20222800 0%, #202228C9 79%)'}}>

                <Box sx={{  }}>
                    <Typography variant='h5'>
                        {props.title}
                    </Typography>
                    <Typography>
                        {props.text}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}