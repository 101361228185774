import React from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { apiSingleLivestream } from './LivestreamsRow';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'


export interface LivestreamsCardProps {
    data?: apiSingleLivestream
}

export default function LivestreamsCard(props: LivestreamsCardProps) {
    const theme = useTheme();

    dayjs.extend(utc)
    dayjs.extend(tz)

    return (

        <Box
            bgcolor={"#242431"}
            sx={{ height: 400, aspectRatio: 0.95, borderRadius: 4, overflow: 'clip' }}
        >
            <Box height="45%" width="100%" sx={{ borderRadius: 4, overflow: 'clip' }}>
                <img src={props.data?.attributes.thumb?.data?.attributes?.url ? props.data?.attributes.thumb?.data?.attributes?.url : "placeholder.jpg"} style={{ width: '100%', objectFit: 'cover' }}></img>
            </Box>
            <Box width='100%' p={4}>
                <Typography sx={{ fontWeight: 600, fontSize: 12, letterSpacing: '2.4px', textTransform: 'uppercase', color: theme.palette.secondary.main }}>
                    {props.data?.attributes.datetime ? dayjs.utc(props.data?.attributes.datetime).format("DD MMM @ h A UTC") : ""}
                </Typography>
                <Typography py={1}>
                    {props.data?.attributes.title}
                </Typography>
                <Typography sx={{ color: '#ABADB5', height: 100, overflow: 'hidden' }}>
                    {props.data?.attributes.description}
                </Typography>
            </Box>

        </Box>
    )
}