import React, { useState } from 'react';
import CollectionChip from './CollectionChip';
import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion"

export default function CollectionsRow() {

    let collections = [
        "Free flap surgery",
        "Facial aesthetics",
        "facial palsy",
        "Global medicine",
        "hand surgery",
        "Orthopedics",
        "Dentofacial trauma",
        "ENT",
        "Craniomaxillofacial trauma",
        "Neurosurgery",
        "Ophthalmology",
        "Urology",
        "Pediatric surgery",
        "Plastic surgery",
        "Laparoscopy",
        "Microsurgery",
        "Rhinoplasty",
        "Sigmoidoscopy",
        "Bronchoscopy",
        "Emergency medicine",
        "Tumours"

    ]

    function generateChips() {
        let chips = [];

        for (let i = 0; i < collections.length; i++) {
            chips.push(
                <CollectionChip text={collections[i]} key={collections[i]}/>
            )
        }
        return chips
    }

    return (
        <>
            <Box>

                <motion.div
                    animate={{ x: '+40%' }}
                    transition={{ delay: 0, ease: "linear", duration: 150, repeatType: 'reverse', repeat: 99 }}
                >
                    <Box sx={{ display: 'flex', gap: 2, transform: 'translateX(-40%)' }}>
                        {
                            generateChips()
                        }
                    </Box>
                </motion.div>
                <motion.div
                    animate={{ x: '-30%' }}
                    transition={{ delay: 0, ease: "linear", duration: 150, repeatType: 'reverse', repeat: 99 }}
                    style={{marginTop: 16}}
                >
                    <Box sx={{ display: 'flex', gap: 2, transform: 'translateX(0%)' }}>
                        {
                            generateChips()
                        }
                    </Box>
                </motion.div>
            </Box>
        </>
    )
}