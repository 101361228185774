import { LineWeight } from '@mui/icons-material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

export let theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
          main: '#3B38CE',
        },
        secondary: {
          main: '#7B78FF',
        },
        background: {
          default: '#0E1015',
          paper: '#242431',
        },
      },
      components: {
        MuiMenu: {
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: "contained", color: "primary" },
                    style: {
                        borderRadius: 100,
                        fontWeight: 500,
                        color: "#fff",
                        fontFamily: 'Montserrat',
                        fontSize: 14,
                        textTransform: 'none',
                        paddingLeft: 32,
                        paddingRight: 32,
                        paddingTop: 16,
                        paddingBottom: 16,
                    },
                },
                {
                    props: { variant: "outlined" },
                    style: {
                        textTransform: 'none',
                        color: '#E6E6E8',
                        borderRadius: '100px',
                        border: '2px solid white',
                        fontSize: 14,
                        fontWeight: 500,
                        letterSpacing: '0.15px',
                        lineHeight: '24px',
                        paddingLeft: 32,
                        paddingRight: 32,
                        paddingTop: 10,
                        paddingBottom: 10,
                        "&:hover": {
                          border: '2px solid white'
                        }
                    },
                    
                },
                {
                    props: { variant: "text" },
                    style: {
                        color: "#ffffff"
                    },
                },
            ],
        }
    },
    typography: {
          fontFamily: 'Montserrat, sans-serif',
        h1: {
          fontFamily: 'League Gothic, sans-serif',
          fontSize: 130,
          textTransform: 'uppercase',
        lineHeight: 1
        },
        h2: {
          fontSize: 48,
          fontWeight: 700,
          lineHeight: 1.2,
        },
        h3: {
        },
        h4: {
            fontSize: 24,
            lineHeight: 1.6
        },
        h5: {
            fontSize: 32,
            fontWeight: 600,
        },
        h6:{
          lineHeight: 1,
        },
        subtitle1: {
          fontSize: 14,
          fontweight: 400,
          lineHeight: '24px',
          letterSpacing:'-0.225px'
        },
        subtitle2: {
        },
        body1: {
          fontWeight: 500,
          fontSize: 16,
        },
        body2: {
        },
        button: {
        },
        caption: {
        },
        overline: {
        },
        fontSize: 16,
      },
    });
    theme = responsiveFontSizes(theme, {factor: 2});
