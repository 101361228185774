import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Container } from "@mui/material";
import LivestreamsCard from './LivestreamsCard';
import dayjs from 'dayjs';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface getLivestreamsType {
    data: apiSingleLivestream[],
    meta: {
        pagination: apiPagination;
    }
    error?: boolean
}

export type apiPagination = {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
}

export interface fileType {
    data: {
        attributes: {
            createdAt: string;
            ext: string;
            height: string | number;
            name: string;
            size: number;
            url: string;
            width: string | number;
        },
        id: number
    }
}

export interface apiSingleLivestream {
    "id": number;
    "attributes": {
        "title": string;
        "description": string;
        "createdAt": string;
        "updatedAt": string;
        "publishedAt": string;
        "stream": string;
        "datetime": string;
        "tags": string;
        "video"?: {
            "data": any;
        },
        "thumb"?: fileType;
    }
}

export default function LivestreamsRow() {

    const [data, setData] = useState<getLivestreamsType | { data: [] }>({ data: [] });
    const mobileMode = useMediaQuery('(max-width:600px)');
    const mediumMode = useMediaQuery('(max-width:1200px)')

    async function get(endpoint: string, page?: number, sort?: number): Promise<any> {
        let data = {
            data: [],
            error: true,
            meta: {}
        };
        await fetch(`${process.env.API_HOST}/api${endpoint}&sort=date:desc`, {
            // headers: {
            //   'Authorization': `Bearer ${getToken()}`
            // },
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            throw new Error('Request failed!');                         // Error logging
        }, networkError => {
            console.log("The request failed - " + networkError.message);
            return data;
        }).then((jsonResponse: any) => {
            // console.log(jsonResponse)
            if (jsonResponse.data?.length !== 0)             //? Got a proper response
            {
                // console.log(jsonResponse)
                data = jsonResponse;
            }
            else {
                console.log("Got a response but the array was empty")
            }
        });
        return data;
    };


    async function getFutureLivestreams(): Promise<void> {
        let req = await get("/livestreams?populate=*&sort=datetime:asc&filters[datetime][$gte]=" + dayjs().toISOString())
        setData(req);
    };

    useEffect(() => {
        getFutureLivestreams();
    }, [])

    function howManyCards(): number {
        if (mobileMode) {
            return 1
        }
        if (mediumMode) {
            return 2
        }
        return 3
    }

    if (!data?.data.length) {
        return (<>
        </>)
    }

    return (
        <Container sx={{ mt: 12 }}>
            <Typography variant='h2' pb={8}>Upcoming Live streams</Typography>
            <Box display="flex" gap={4} justifyContent={'space-around'}>
                {data?.data?.slice(0, howManyCards()).map((ls) => {
                    return (
                        <LivestreamsCard data={ls} key={ls.id}/>
                    )
                })}
            </Box>
        </Container>
    )
}