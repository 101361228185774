import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Toast from "react-hot-toast";
import z from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArrowCircleDown, CheckCircle } from "@mui/icons-material";
import ConfirmationBox from "./components/ConfirmationBox";

export const resetPasswordSchema = z
    .object({
        password: z.string().min(8, "Password must have at least 8 characters"),
        passwordConfirmation: z
            .string()
            .min(8, "Password must have at least 8 characters"),
    })
    .refine((data) => data.password === data.passwordConfirmation, {
        message: "Passwords not match",
        path: ["passwordConfirmation"],
    });

export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;

const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const mobileMode = useMediaQuery("(max-width:600px)");
    const mediumMode = useMediaQuery("(max-width:900px)");

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitSuccessful },
        reset,
    } = useForm<ResetPasswordSchema>({
        resolver: zodResolver(resetPasswordSchema),
    });

    console.log({ errors });

    const handleOnSubmit: SubmitHandler<ResetPasswordSchema> = async (data) => {
        reset();
        const body = {
            ...data,
            code,
        };

        const response = await fetch(
            `${process.env.API_HOST}/api/auth/reset-password`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            }
        );

        const jsonResponse = await response.json();
        if (response.ok) {
            Toast.success("Password updated");
        } else {
            const message = jsonResponse?.error?.message ?? "Request failed!";
            Toast.error(message);
            throw new Error(message);
        }
    };

    useEffect(() => {
        if (!code) {
            navigate("/");
        }
    }, []);

    if (isSubmitSuccessful) {
        return (
            <ConfirmationBox>Your password has been updated</ConfirmationBox>
        );
    }

    return (
        <Box
            component="form"
            minHeight="100vh"
            display="flex"
            alignItems="center"
            sx={{
                width: "100%",
                overflow: "hidden",
                position: "relative",
            }}
            onSubmit={handleSubmit(handleOnSubmit)}
            noValidate
        >
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: -10,
                    width: mobileMode ? "600px" : "1000px",
                    height: mobileMode ? "600px" : "1000px",
                    transform: "translate(+20%, -20%)",
                    background:
                        "radial-gradient(circle, rgba(59,56,206,0.7) 0%, rgba(0,0,0,0) 70%)",
                }}
            />
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: -10,
                    opacity: mediumMode ? 0 : 1,
                    width: "45vw",
                    height: "45vw",
                    transform: "translate(-20%, -30%)",
                    background:
                        "radial-gradient(circle, rgba(81,196,221,0.6) 0%, rgba(38,36,163,0.3) 40%, rgba(0,0,0,0)60%)",
                }}
            />
            <Container maxWidth="sm" sx={{ zIndex: 10 }}>
                <Typography variant="h4" textAlign="center" mb={4}>
                    Reset Password
                </Typography>
                <Box mb={2}>
                    <TextField
                        type="password"
                        label="Password"
                        variant="outlined"
                        fullWidth
                        {...register("password")}
                    />
                    {errors.password && (
                        <Typography
                            variant="body1"
                            fontSize="0.9em"
                            color="red"
                            mt={0.5}
                        >
                            {errors.password.message ?? "Error"}
                        </Typography>
                    )}
                </Box>
                <Box mb={4}>
                    <TextField
                        type="password"
                        label="Password Confirmation"
                        variant="outlined"
                        fullWidth
                        {...register("passwordConfirmation")}
                    />
                    {errors.passwordConfirmation && (
                        <Typography
                            variant="body1"
                            fontSize="0.9em"
                            color="red"
                            mt={0.5}
                        >
                            {errors.passwordConfirmation.message ?? "Error"}
                        </Typography>
                    )}
                </Box>
                <Box textAlign="center">
                    <Button type="submit" variant="contained" size="medium">
                        Submit
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default ResetPassword;
