
import React, { Component } from "react";

export interface LogoProps {
    // children?: React.ReactNode;
    color?: string;
    size?: number;
}

export function Logo(props: LogoProps) {

        return (
            <svg width={props.size ? props.size : "160"} height={props.size ? props.size*(50/160) : "50"} viewBox="0 0 170 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Artboard1">
                    <g id="Sub">
                        <path id="Vector" d="M10.0577 36.5967L7.49721 42.3778H6.89474L4.33423 36.5967H4.99518L7.20838 41.6176L9.43841 36.5967H10.0577Z" fill="white" />
                        <path id="Vector_2" d="M10.7435 38.0336H11.33V42.3777H10.7435V38.0336ZM11.0412 37.0839C10.9171 37.0839 10.8179 37.0422 10.7355 36.9598C10.6531 36.8774 10.6115 36.7782 10.6115 36.6621C10.6115 36.5549 10.6531 36.4557 10.7355 36.3733C10.8179 36.2909 10.9171 36.2493 11.0412 36.2493C11.1652 36.2493 11.2644 36.2909 11.3468 36.3653C11.4292 36.4477 11.4709 36.547 11.4709 36.6542C11.4709 36.7782 11.4292 36.8774 11.3468 36.9598C11.2644 37.0422 11.1652 37.0839 11.0412 37.0839Z" fill="white" />
                        <path id="Vector_3" d="M13.5263 38.8842C13.6663 38.5954 13.8736 38.3721 14.1456 38.2233C14.4096 38.0744 14.7481 38 15.153 38V38.5697L15.013 38.5617C14.5585 38.5617 14.1952 38.7017 13.9392 38.9826C13.6831 39.2634 13.5511 39.6595 13.5511 40.1636V42.3768H12.9646V38.0328H13.5263V38.8833V38.8842Z" fill="white" />
                        <path id="Vector_4" d="M18.6882 42.1129C18.581 42.2121 18.4401 42.2865 18.2833 42.3361C18.1185 42.3937 17.9448 42.4185 17.7712 42.4185C17.3663 42.4185 17.0526 42.3113 16.8294 42.088C16.6061 41.8648 16.4989 41.5591 16.4989 41.1551V38.529H15.7228V38.0338H16.4989V37.084H17.0854V38.0338H18.4064V38.529H17.0854V41.1223C17.0854 41.3784 17.1519 41.5768 17.2839 41.7088C17.4079 41.8488 17.5975 41.9153 17.8376 41.9153C17.9617 41.9153 18.0768 41.8984 18.1929 41.8577C18.3001 41.8161 18.3993 41.7664 18.4817 41.6929L18.6882 42.1137V42.1129Z" fill="white" />
                        <path id="Vector_5" d="M23.6682 38.0337V42.3777H23.1065V41.5847C22.9497 41.8488 22.7432 42.0552 22.4704 42.1961C22.1975 42.3449 21.8918 42.4193 21.5454 42.4193C20.9757 42.4193 20.5292 42.2625 20.2075 41.94C19.8771 41.6264 19.7123 41.1639 19.7123 40.5526V38.0337H20.2988V40.495C20.2988 40.9495 20.4149 41.2959 20.6373 41.536C20.8596 41.7761 21.1821 41.8913 21.6039 41.8913C22.0584 41.8913 22.4216 41.7513 22.6857 41.4704C22.9497 41.1976 23.0817 40.8095 23.0817 40.3142V38.0346H23.6682V38.0337Z" fill="white" />
                        <path id="Vector_6" d="M26.8312 38C27.4009 38 27.8306 38.14 28.1363 38.4208C28.442 38.7097 28.5908 39.1314 28.5908 39.6843V42.3768H28.0291V41.6999C27.8971 41.9232 27.7066 42.1048 27.4506 42.2288C27.1945 42.3529 26.8888 42.4184 26.5336 42.4184C26.0463 42.4184 25.6662 42.3024 25.3773 42.0711C25.0885 41.8399 24.9476 41.5342 24.9476 41.1541C24.9476 40.774 25.0796 40.4852 25.3525 40.2619C25.6166 40.0387 26.0383 39.9235 26.6248 39.9235H28.0043V39.6595C28.0043 39.2882 27.8971 38.9985 27.6907 38.8009C27.4842 38.6113 27.1786 38.5121 26.7737 38.5121C26.5008 38.5121 26.2367 38.5537 25.9807 38.6441C25.7246 38.7433 25.5102 38.8674 25.3286 39.0242L25.0646 38.5865C25.2879 38.3969 25.5519 38.2561 25.8575 38.1488C26.1632 38.0496 26.4937 38 26.8321 38H26.8312ZM26.6248 41.9559C26.9553 41.9559 27.2361 41.8815 27.4754 41.7247C27.7146 41.5759 27.8882 41.3614 28.0043 41.0726V40.362H26.6416C25.8983 40.362 25.5271 40.6181 25.5271 41.1382C25.5271 41.3942 25.6263 41.5927 25.8159 41.7327C26.0055 41.8815 26.2784 41.9559 26.6257 41.9559H26.6248Z" fill="white" />
                        <path id="Vector_7" d="M30.7704 36.2493H30.1838V42.3777H30.7704V36.2493Z" fill="white" />
                        <path id="Vector_8" d="M38.6406 42.3778L37.3196 40.5198C37.1707 40.5367 37.0139 40.5446 36.8571 40.5446H35.3048V42.3778H34.6935V36.5967H36.8571C37.5924 36.5967 38.1701 36.7703 38.5918 37.1256C39.0127 37.4809 39.2191 37.9593 39.2191 38.5795C39.2191 39.034 39.103 39.4132 38.8807 39.7189C38.6494 40.0325 38.3189 40.2558 37.8981 40.3958L39.3104 42.3778H38.6415H38.6406ZM36.8402 40.0237C37.4099 40.0237 37.8476 39.8996 38.1533 39.6436C38.4589 39.3875 38.6078 39.0323 38.6078 38.5786C38.6078 38.125 38.4589 37.7529 38.1533 37.4968C37.8476 37.2488 37.4099 37.1256 36.8402 37.1256H35.3039V40.0246H36.8402V40.0237Z" fill="white" />
                        <path id="Vector_9" d="M44.3234 40.3869H40.6899C40.7227 40.8414 40.8964 41.2046 41.21 41.4766C41.5236 41.7575 41.9206 41.8975 42.399 41.8975C42.6719 41.8975 42.9191 41.8479 43.1423 41.7486C43.3656 41.6574 43.5632 41.5174 43.7289 41.3278L44.0593 41.7079C43.8697 41.9391 43.6217 42.1128 43.3408 42.2368C43.052 42.3608 42.7295 42.4184 42.383 42.4184C41.9374 42.4184 41.5405 42.3192 41.202 42.1296C40.8547 41.94 40.5827 41.6839 40.3922 41.3446C40.2026 41.0062 40.1034 40.6261 40.1034 40.2052C40.1034 39.7844 40.1946 39.4043 40.3843 39.0658C40.5659 38.7274 40.8219 38.4713 41.1444 38.2809C41.4669 38.0913 41.8213 38 42.2262 38C42.6311 38 42.9864 38.0913 43.308 38.2809C43.6305 38.4705 43.8777 38.7265 44.0593 39.057C44.241 39.3954 44.3322 39.7755 44.3322 40.2052L44.3242 40.3869H44.3234ZM42.2253 38.5041C41.8045 38.5041 41.4572 38.6361 41.1763 38.9002C40.8875 39.173 40.7307 39.5195 40.689 39.9492H43.7696C43.728 39.5195 43.5711 39.173 43.2903 38.9002C43.0015 38.6361 42.6462 38.5041 42.2253 38.5041Z" fill="white" />
                        <path id="Vector_10" d="M46.9246 38C47.4943 38 47.924 38.14 48.2297 38.4208C48.5354 38.7097 48.6842 39.1314 48.6842 39.6843V42.3768H48.1225V41.6999C47.9905 41.9232 47.8 42.1048 47.5439 42.2288C47.2879 42.3529 46.9822 42.4184 46.6269 42.4184C46.1396 42.4184 45.7596 42.3024 45.4707 42.0711C45.1819 41.8399 45.041 41.5342 45.041 41.1541C45.041 40.774 45.173 40.4852 45.4459 40.2619C45.7099 40.0387 46.1317 39.9235 46.7182 39.9235H48.0977V39.6595C48.0977 39.2882 47.9905 38.9985 47.784 38.8009C47.5776 38.6113 47.2719 38.5121 46.867 38.5121C46.5942 38.5121 46.3301 38.5537 46.0741 38.6441C45.818 38.7433 45.6036 38.8674 45.422 39.0242L45.158 38.5865C45.3812 38.3969 45.6453 38.2561 45.9509 38.1488C46.2566 38.0496 46.5871 38 46.9255 38H46.9246ZM46.7182 41.9559C47.0487 41.9559 47.3295 41.8815 47.5687 41.7247C47.808 41.5759 47.9816 41.3614 48.0977 41.0726V40.362H46.735C45.9917 40.362 45.6205 40.6181 45.6205 41.1382C45.6205 41.3942 45.7197 41.5927 45.9093 41.7327C46.0989 41.8815 46.3718 41.9559 46.7191 41.9559H46.7182Z" fill="white" />
                        <path id="Vector_11" d="M50.8638 36.2493H50.2772V42.3777H50.8638V36.2493Z" fill="white" />
                        <path id="Vector_12" d="M52.4992 38.0336H53.0857V42.3777H52.4992V38.0336ZM52.7969 37.0839C52.6729 37.0839 52.5736 37.0422 52.4912 36.9598C52.4088 36.8774 52.3672 36.7782 52.3672 36.6621C52.3672 36.5549 52.4088 36.4557 52.4912 36.3733C52.5736 36.2909 52.6729 36.2493 52.7969 36.2493C52.9209 36.2493 53.0202 36.2909 53.1026 36.3653C53.185 36.4477 53.2266 36.547 53.2266 36.6542C53.2266 36.7782 53.185 36.8774 53.1026 36.9598C53.0202 37.0422 52.9209 37.0839 52.7969 37.0839Z" fill="white" />
                        <path id="Vector_13" d="M57.0089 42.1129C56.9017 42.2121 56.7608 42.2865 56.604 42.3361C56.4392 42.3937 56.2655 42.4185 56.0919 42.4185C55.687 42.4185 55.3733 42.3113 55.1501 42.088C54.9268 41.8648 54.8196 41.5591 54.8196 41.1551V38.529H54.0435V38.0338H54.8196V37.084H55.4061V38.0338H56.7271V38.529H55.4061V41.1223C55.4061 41.3784 55.4726 41.5768 55.6046 41.7088C55.7286 41.8488 55.9182 41.9153 56.1583 41.9153C56.2823 41.9153 56.3975 41.8984 56.5136 41.8577C56.6208 41.8161 56.72 41.7664 56.8024 41.6929L57.0089 42.1137V42.1129Z" fill="white" />
                        <path id="Vector_14" d="M61.6418 38.0337L59.4693 42.8978C59.2957 43.3027 59.0892 43.5915 58.858 43.7652C58.6267 43.9388 58.3459 44.0212 58.0243 44.0212C57.8178 44.0212 57.6194 43.9884 57.4377 43.922C57.2561 43.8555 57.0993 43.7572 56.9673 43.6243L57.2402 43.1866C57.4634 43.4099 57.7275 43.5171 58.0331 43.5171C58.2316 43.5171 58.4044 43.4595 58.5452 43.3523C58.6852 43.2451 58.8093 43.0546 58.9333 42.7906L59.1229 42.3697L57.1817 38.0337H57.793L59.4286 41.7256L61.0641 38.0337H61.6427H61.6418Z" fill="white" />
                        <path id="Vector_15" d="M64.631 38.0336H65.2176V42.3777H64.631V38.0336ZM64.9287 37.0839C64.8047 37.0839 64.7055 37.0422 64.6231 36.9598C64.5407 36.8774 64.499 36.7782 64.499 36.6621C64.499 36.5549 64.5407 36.4557 64.6231 36.3733C64.7055 36.2909 64.8047 36.2493 64.9287 36.2493C65.0528 36.2493 65.152 36.2909 65.2344 36.3653C65.3168 36.4477 65.3584 36.547 65.3584 36.6542C65.3584 36.7782 65.3168 36.8774 65.2344 36.9598C65.152 37.0422 65.0528 37.0839 64.9287 37.0839Z" fill="white" />
                        <path id="Vector_16" d="M69.0663 38C69.6112 38 70.0489 38.1568 70.3714 38.4705C70.6939 38.793 70.8507 39.2554 70.8507 39.8579V42.3768H70.2642V39.9155C70.2642 39.461 70.1481 39.1225 69.9258 38.8833C69.7025 38.6441 69.3809 38.528 68.9591 38.528C68.4887 38.528 68.1254 38.668 67.8525 38.9409C67.5797 39.2218 67.4397 39.6098 67.4397 40.0971V42.3768H66.8531V38.0328H67.4149V38.8337C67.5717 38.5697 67.795 38.3633 68.0838 38.2144C68.3646 38.0744 68.6951 38 69.0663 38Z" fill="white" />
                        <path id="Vector_17" d="M80.7358 36.5967V42.3778H80.1492V37.7529L77.8784 41.6424H77.5896L75.3188 37.7777V42.3778H74.7323V36.5967H75.2364L77.7473 40.8831L80.2334 36.5967H80.7375H80.7358Z" fill="white" />
                        <path id="Vector_18" d="M86.2776 40.3869H82.6441C82.6769 40.8414 82.8506 41.2046 83.1642 41.4766C83.4779 41.7575 83.8748 41.8975 84.3532 41.8975C84.6261 41.8975 84.8733 41.8479 85.0966 41.7486C85.3198 41.6574 85.5174 41.5174 85.6831 41.3278L86.0136 41.7079C85.824 41.9391 85.5759 42.1128 85.295 42.2368C85.0062 42.3608 84.6837 42.4184 84.3373 42.4184C83.8916 42.4184 83.4947 42.3192 83.1562 42.1296C82.8089 41.94 82.5369 41.6839 82.3465 41.3446C82.1568 41.0062 82.0576 40.6261 82.0576 40.2052C82.0576 39.7844 82.1489 39.4043 82.3385 39.0658C82.5201 38.7274 82.7762 38.4713 83.0987 38.2809C83.4212 38.0913 83.7756 38 84.1804 38C84.5853 38 84.9406 38.0913 85.2622 38.2809C85.5847 38.4705 85.8319 38.7265 86.0136 39.057C86.1952 39.3954 86.2864 39.7755 86.2864 40.2052L86.2785 40.3869H86.2776ZM84.1796 38.5041C83.7587 38.5041 83.4114 38.6361 83.1306 38.9002C82.8417 39.173 82.6849 39.5195 82.6433 39.9492H85.7238C85.6822 39.5195 85.5254 39.173 85.2445 38.9002C84.9557 38.6361 84.6004 38.5041 84.1796 38.5041Z" fill="white" />
                        <path id="Vector_19" d="M91.4473 36.2493V42.3777H90.8855V41.5192C90.7119 41.808 90.4806 42.0313 90.1918 42.1881C89.903 42.3449 89.5725 42.4194 89.2092 42.4194C88.8043 42.4194 88.4331 42.3281 88.1026 42.1385C87.7722 41.9489 87.5161 41.6928 87.3265 41.3535C87.1369 41.0151 87.0457 40.635 87.0457 40.2053C87.0457 39.7756 87.1369 39.3955 87.3265 39.057C87.5161 38.7186 87.7722 38.4625 88.1026 38.272C88.4331 38.0904 88.8043 37.9992 89.2092 37.9992C89.5645 37.9992 89.8782 38.0736 90.167 38.2136C90.4479 38.3624 90.6791 38.5768 90.8607 38.8577V36.2476H91.4473V36.2493ZM89.2589 41.8984C89.5645 41.8984 89.8374 41.824 90.0846 41.684C90.3238 41.552 90.5143 41.3455 90.6631 41.0895C90.8031 40.8334 90.8696 40.5357 90.8696 40.2062C90.8696 39.8766 90.8031 39.5789 90.6631 39.3228C90.5143 39.0668 90.3247 38.8603 90.0846 38.7204C89.8365 38.5804 89.5645 38.5139 89.2589 38.5139C88.9532 38.5139 88.6723 38.5804 88.4331 38.7204C88.185 38.8603 87.9954 39.0677 87.8546 39.3228C87.7146 39.5789 87.6402 39.8766 87.6402 40.2062C87.6402 40.5357 87.7146 40.8334 87.8546 41.0895C87.9945 41.3455 88.185 41.552 88.4331 41.684C88.6723 41.824 88.9532 41.8984 89.2589 41.8984Z" fill="white" />
                        <path id="Vector_20" d="M93.0828 38.0336H93.6693V42.3777H93.0828V38.0336ZM93.3805 37.0839C93.2565 37.0839 93.1572 37.0422 93.0748 36.9598C92.9924 36.8774 92.9508 36.7782 92.9508 36.6621C92.9508 36.5549 92.9924 36.4557 93.0748 36.3733C93.1572 36.2909 93.2565 36.2493 93.3805 36.2493C93.5045 36.2493 93.6038 36.2909 93.6862 36.3653C93.7686 36.4477 93.8102 36.547 93.8102 36.6542C93.8102 36.7782 93.7686 36.8774 93.6862 36.9598C93.6038 37.0422 93.5045 37.0839 93.3805 37.0839Z" fill="white" />
                        <path id="Vector_21" d="M97.1043 42.4184C96.6746 42.4184 96.2946 42.3272 95.9561 42.1376C95.6088 41.948 95.3448 41.6831 95.1552 41.3446C94.9656 41.0062 94.8663 40.6261 94.8663 40.2052C94.8663 39.7844 94.9656 39.4043 95.1552 39.0658C95.3448 38.7274 95.6097 38.4713 95.9561 38.2809C96.2946 38.0913 96.6746 38 97.1043 38C97.4756 38 97.8149 38.0744 98.1117 38.2144C98.4005 38.3633 98.6318 38.5777 98.8054 38.8585L98.3678 39.1562C98.2278 38.9418 98.0453 38.7761 97.8229 38.6689C97.5996 38.5617 97.3604 38.5121 97.1043 38.5121C96.7907 38.5121 96.5098 38.5786 96.2618 38.7185C96.0057 38.8585 95.8161 39.0658 95.6752 39.321C95.5353 39.5771 95.4608 39.8748 95.4608 40.2043C95.4608 40.5339 95.5353 40.8316 95.6752 41.0877C95.8152 41.3437 96.0057 41.5502 96.2618 41.6822C96.5098 41.8222 96.7907 41.8966 97.1043 41.8966C97.3604 41.8966 97.5996 41.847 97.8229 41.7398C98.0461 41.6326 98.2278 41.4757 98.3678 41.2604L98.8054 41.5581C98.6318 41.839 98.4005 42.0534 98.1037 42.1943C97.806 42.3431 97.4765 42.4175 97.1043 42.4175V42.4184Z" fill="white" />
                        <path id="Vector_22" d="M99.9537 38.0336H100.54V42.3777H99.9537V38.0336ZM100.251 37.0839C100.127 37.0839 100.028 37.0422 99.9457 36.9598C99.8633 36.8774 99.8217 36.7782 99.8217 36.6621C99.8217 36.5549 99.8633 36.4557 99.9457 36.3733C100.028 36.2909 100.127 36.2493 100.251 36.2493C100.375 36.2493 100.475 36.2909 100.557 36.3653C100.639 36.4477 100.681 36.547 100.681 36.6542C100.681 36.7782 100.639 36.8774 100.557 36.9598C100.475 37.0422 100.375 37.0839 100.251 37.0839Z" fill="white" />
                        <path id="Vector_23" d="M104.389 38C104.934 38 105.372 38.1568 105.694 38.4705C106.017 38.793 106.173 39.2554 106.173 39.8579V42.3768H105.587V39.9155C105.587 39.461 105.471 39.1225 105.248 38.8833C105.025 38.6441 104.704 38.528 104.282 38.528C103.811 38.528 103.448 38.668 103.175 38.9409C102.902 39.2218 102.762 39.6098 102.762 40.0971V42.3768H102.176V38.0328H102.737V38.8337C102.894 38.5697 103.118 38.3633 103.406 38.2144C103.687 38.0744 104.018 38 104.389 38Z" fill="white" />
                        <path id="Vector_24" d="M111.565 40.3869H107.932C107.965 40.8414 108.138 41.2046 108.452 41.4766C108.766 41.7575 109.163 41.8975 109.641 41.8975C109.914 41.8975 110.161 41.8479 110.384 41.7486C110.608 41.6574 110.805 41.5174 110.971 41.3278L111.301 41.7079C111.112 41.9391 110.864 42.1128 110.583 42.2368C110.294 42.3608 109.972 42.4184 109.625 42.4184C109.179 42.4184 108.783 42.3192 108.444 42.1296C108.097 41.94 107.825 41.6839 107.634 41.3446C107.445 41.0062 107.345 40.6261 107.345 40.2052C107.345 39.7844 107.437 39.4043 107.626 39.0658C107.808 38.7274 108.064 38.4713 108.386 38.2809C108.709 38.0913 109.063 38 109.468 38C109.873 38 110.228 38.0913 110.55 38.2809C110.873 38.4705 111.12 38.7265 111.301 39.057C111.483 39.3954 111.574 39.7755 111.574 40.2052L111.566 40.3869H111.565ZM109.467 38.5041C109.047 38.5041 108.699 38.6361 108.418 38.9002C108.13 39.173 107.973 39.5195 107.931 39.9492H111.012C110.97 39.5195 110.813 39.173 110.532 38.9002C110.244 38.6361 109.888 38.5041 109.467 38.5041Z" fill="white" />
                        <path id="Vector_25" d="M116.454 38C117.024 38 117.454 38.14 117.759 38.4208C118.065 38.7097 118.214 39.1314 118.214 39.6843V42.3768H117.652V41.6999C117.52 41.9232 117.33 42.1048 117.074 42.2288C116.818 42.3529 116.512 42.4184 116.157 42.4184C115.669 42.4184 115.289 42.3024 115 42.0711C114.712 41.8399 114.571 41.5342 114.571 41.1541C114.571 40.774 114.703 40.4852 114.976 40.2619C115.24 40.0387 115.661 39.9235 116.248 39.9235H117.627V39.6595C117.627 39.2882 117.52 38.9985 117.314 38.8009C117.107 38.6113 116.802 38.5121 116.397 38.5121C116.124 38.5121 115.86 38.5537 115.604 38.6441C115.348 38.7433 115.133 38.8674 114.952 39.0242L114.688 38.5865C114.911 38.3969 115.175 38.2561 115.481 38.1488C115.786 38.0496 116.117 38 116.455 38H116.454ZM116.248 41.9559C116.578 41.9559 116.859 41.8815 117.098 41.7247C117.338 41.5759 117.511 41.3614 117.627 41.0726V40.362H116.265C115.521 40.362 115.15 40.6181 115.15 41.1382C115.15 41.3942 115.249 41.5927 115.439 41.7327C115.629 41.8815 115.901 41.9559 116.249 41.9559H116.248Z" fill="white" />
                        <path id="Vector_26" d="M122.022 38C122.567 38 123.004 38.1568 123.327 38.4705C123.649 38.793 123.806 39.2554 123.806 39.8579V42.3768H123.22V39.9155C123.22 39.461 123.104 39.1225 122.881 38.8833C122.658 38.6441 122.336 38.528 121.915 38.528C121.444 38.528 121.081 38.668 120.808 38.9409C120.535 39.2218 120.395 39.6098 120.395 40.0971V42.3768H119.809V38.0328H120.37V38.8337C120.527 38.5697 120.751 38.3633 121.039 38.2144C121.32 38.0744 121.651 38 122.022 38Z" fill="white" />
                        <path id="Vector_27" d="M129.379 36.2493V42.3777H128.817V41.5192C128.644 41.808 128.413 42.0313 128.124 42.1881C127.835 42.3449 127.504 42.4194 127.141 42.4194C126.736 42.4194 126.365 42.3281 126.035 42.1385C125.704 41.9489 125.448 41.6928 125.258 41.3535C125.069 41.0151 124.978 40.635 124.978 40.2053C124.978 39.7756 125.069 39.3955 125.258 39.057C125.448 38.7186 125.704 38.4625 126.035 38.272C126.365 38.0904 126.736 37.9992 127.141 37.9992C127.496 37.9992 127.81 38.0736 128.099 38.2136C128.38 38.3624 128.611 38.5768 128.793 38.8577V36.2476H129.379V36.2493ZM127.191 41.8984C127.496 41.8984 127.769 41.824 128.016 41.684C128.256 41.552 128.446 41.3455 128.595 41.0895C128.735 40.8334 128.801 40.5357 128.801 40.2062C128.801 39.8766 128.735 39.5789 128.595 39.3228C128.446 39.0668 128.257 38.8603 128.016 38.7204C127.768 38.5804 127.496 38.5139 127.191 38.5139C126.885 38.5139 126.604 38.5804 126.365 38.7204C126.117 38.8603 125.927 39.0677 125.786 39.3228C125.646 39.5789 125.572 39.8766 125.572 40.2062C125.572 40.5357 125.646 40.8334 125.786 41.0895C125.926 41.3455 126.117 41.552 126.365 41.684C126.604 41.824 126.885 41.8984 127.191 41.8984Z" fill="white" />
                        <path id="Vector_28" d="M134.897 42.4273C134.467 42.4273 134.055 42.3608 133.666 42.2208C133.27 42.0808 132.965 41.8983 132.749 41.676L132.989 41.2055C133.195 41.4119 133.476 41.5856 133.822 41.7096C134.17 41.8416 134.524 41.9081 134.896 41.9081C135.416 41.9081 135.804 41.8088 136.06 41.6192C136.316 41.4296 136.448 41.1895 136.448 40.8839C136.448 40.6526 136.382 40.471 136.242 40.3301C136.093 40.1902 135.928 40.0821 135.722 40.0076C135.516 39.9332 135.235 39.8588 134.871 39.7684C134.434 39.6612 134.086 39.554 133.83 39.4459C133.574 39.3467 133.351 39.1899 133.169 38.9834C132.98 38.777 132.889 38.4882 132.889 38.1329C132.889 37.8441 132.963 37.5712 133.12 37.3319C133.277 37.0927 133.508 36.9022 133.83 36.7623C134.153 36.6223 134.549 36.5479 135.019 36.5479C135.35 36.5479 135.671 36.5975 135.994 36.6799C136.308 36.7711 136.58 36.9031 136.812 37.06L136.605 37.5472C136.366 37.3904 136.101 37.2664 135.829 37.184C135.556 37.1096 135.284 37.0679 135.019 37.0679C134.515 37.0679 134.136 37.1672 133.88 37.3568C133.624 37.5552 133.492 37.8113 133.492 38.1169C133.492 38.3482 133.566 38.5298 133.706 38.6707C133.846 38.8116 134.02 38.9188 134.235 38.9932C134.442 39.0676 134.731 39.15 135.086 39.2413C135.507 39.3485 135.854 39.4477 136.118 39.5469C136.374 39.6541 136.597 39.803 136.779 40.0094C136.961 40.2158 137.052 40.4967 137.052 40.852C137.052 41.1408 136.977 41.4057 136.821 41.6449C136.664 41.893 136.416 42.0746 136.094 42.2146C135.772 42.3546 135.368 42.429 134.896 42.429L134.897 42.4273Z" fill="white" />
                        <path id="Vector_29" d="M142.173 38.0337V42.3777H141.611V41.5847C141.454 41.8488 141.248 42.0552 140.975 42.1961C140.702 42.3449 140.396 42.4193 140.05 42.4193C139.48 42.4193 139.034 42.2625 138.712 41.94C138.382 41.6264 138.217 41.1639 138.217 40.5526V38.0337H138.803V40.495C138.803 40.9495 138.92 41.2959 139.142 41.536C139.364 41.7761 139.687 41.8913 140.109 41.8913C140.563 41.8913 140.926 41.7513 141.19 41.4704C141.454 41.1976 141.586 40.8095 141.586 40.3142V38.0346H142.173V38.0337Z" fill="white" />
                        <path id="Vector_30" d="M144.378 38.8842C144.518 38.5954 144.725 38.3721 144.997 38.2233C145.261 38.0744 145.6 38 146.005 38V38.5697L145.865 38.5617C145.41 38.5617 145.047 38.7017 144.791 38.9826C144.535 39.2634 144.403 39.6595 144.403 40.1636V42.3768H143.816V38.0328H144.378V38.8833V38.8842Z" fill="white" />
                        <path id="Vector_31" d="M151.042 38.0338V41.8497C151.042 42.5851 150.861 43.1379 150.506 43.4932C150.142 43.8485 149.597 44.0222 148.87 44.0222C148.465 44.0222 148.085 43.9646 147.731 43.8485C147.367 43.7245 147.07 43.5597 146.847 43.3532L147.145 42.9076C147.351 43.0972 147.607 43.2381 147.913 43.3453C148.211 43.4525 148.524 43.5021 148.855 43.5021C149.409 43.5021 149.813 43.378 150.069 43.122C150.325 42.858 150.457 42.461 150.457 41.9241V41.3704C150.275 41.6433 150.036 41.8577 149.746 41.9977C149.449 42.1377 149.119 42.2121 148.764 42.2121C148.359 42.2121 147.988 42.1208 147.657 41.9392C147.319 41.7655 147.055 41.5095 146.872 41.1879C146.674 40.8654 146.583 40.5021 146.583 40.0981C146.583 39.6941 146.675 39.33 146.872 39.0083C147.054 38.6858 147.318 38.4387 147.648 38.265C147.979 38.0913 148.35 38.001 148.763 38.001C149.134 38.001 149.464 38.0754 149.762 38.2242C150.06 38.3731 150.299 38.5875 150.481 38.8684V38.0346H151.042V38.0338ZM148.821 41.692C149.135 41.692 149.416 41.6256 149.672 41.4856C149.928 41.3536 150.117 41.1631 150.266 40.9239C150.406 40.6846 150.473 40.4038 150.473 40.0981C150.473 39.7924 150.406 39.5116 150.266 39.2724C150.117 39.0332 149.928 38.8515 149.68 38.7107C149.424 38.5786 149.143 38.5122 148.821 38.5122C148.5 38.5122 148.227 38.5786 147.979 38.7107C147.723 38.8427 147.533 39.0332 147.392 39.2724C147.252 39.5116 147.178 39.7924 147.178 40.0981C147.178 40.4038 147.252 40.6846 147.392 40.9239C147.532 41.1631 147.723 41.3536 147.979 41.4856C148.227 41.6256 148.508 41.692 148.821 41.692Z" fill="white" />
                        <path id="Vector_32" d="M156.468 40.3869H152.835C152.868 40.8414 153.041 41.2046 153.355 41.4766C153.669 41.7575 154.065 41.8975 154.544 41.8975C154.817 41.8975 155.064 41.8479 155.287 41.7486C155.51 41.6574 155.708 41.5174 155.874 41.3278L156.204 41.7079C156.015 41.9391 155.767 42.1128 155.486 42.2368C155.197 42.3608 154.874 42.4184 154.528 42.4184C154.082 42.4184 153.685 42.3192 153.347 42.1296C153 41.94 152.728 41.6839 152.537 41.3446C152.348 41.0062 152.248 40.6261 152.248 40.2052C152.248 39.7844 152.34 39.4043 152.529 39.0658C152.711 38.7274 152.967 38.4713 153.289 38.2809C153.612 38.0913 153.966 38 154.371 38C154.776 38 155.131 38.0913 155.453 38.2809C155.775 38.4705 156.023 38.7265 156.204 39.057C156.386 39.3954 156.477 39.7755 156.477 40.2052L156.469 40.3869H156.468ZM154.37 38.5041C153.949 38.5041 153.602 38.6361 153.321 38.9002C153.032 39.173 152.876 39.5195 152.834 39.9492H155.915C155.873 39.5195 155.716 39.173 155.435 38.9002C155.146 38.6361 154.791 38.5041 154.37 38.5041Z" fill="white" />
                        <path id="Vector_33" d="M158.236 38.8842C158.376 38.5954 158.583 38.3721 158.855 38.2233C159.119 38.0744 159.458 38 159.862 38V38.5697L159.722 38.5617C159.268 38.5617 158.905 38.7017 158.649 38.9826C158.393 39.2634 158.261 39.6595 158.261 40.1636V42.3768H157.674V38.0328H158.236V38.8833V38.8842Z" fill="white" />
                        <path id="Vector_34" d="M164.759 38.0337L162.587 42.8978C162.413 43.3027 162.207 43.5915 161.976 43.7652C161.744 43.9388 161.463 44.0212 161.142 44.0212C160.935 44.0212 160.737 43.9884 160.555 43.922C160.374 43.8555 160.217 43.7572 160.085 43.6243L160.358 43.1866C160.581 43.4099 160.845 43.5171 161.151 43.5171C161.349 43.5171 161.522 43.4595 161.663 43.3523C161.803 43.2451 161.927 43.0546 162.051 42.7906L162.24 42.3697L160.299 38.0337H160.911L162.546 41.7256L164.182 38.0337H164.76H164.759Z" fill="white" />
                    </g>
                    <g id="VRiMS">
                        <path id="Vector_35" d="M39.6746 5.66943L26.5832 35.5848H17.275L4.13489 5.66943H9.51107L22.0549 34.2904L34.6971 5.66943H39.6746Z" fill="white" />
                        <path id="Vector_36" d="M63.9161 35.5848L59.8839 29.8109C58.9386 29.8605 58.2413 29.9101 57.6938 29.9101H49.0828V35.5848H44.1053V5.66943H57.6938C62.2229 5.66943 65.7571 6.76452 68.346 8.90506C70.934 11.0456 72.2284 14.0323 72.2284 17.8146C72.2284 20.5027 71.5816 22.7921 70.2376 24.6836C68.8935 26.5752 67.002 27.9689 64.5628 28.815L69.341 35.5848H63.9152H63.9161ZM57.5449 25.6795C60.6804 25.6795 63.1196 24.9822 64.7622 23.6391C66.4048 22.295 67.2509 20.3538 67.2509 17.8155C67.2509 15.2771 66.4048 13.3359 64.7622 11.9918C63.1196 10.6478 60.6804 10.001 57.5449 10.001H49.0828V25.6804H57.5449V25.6795Z" fill="white" />
                        <path id="Vector_37" d="M79.5955 14.1316H84.3736V35.585H79.5955V14.1316ZM81.985 9.05491C81.0396 9.05491 80.2927 8.75633 79.6956 8.15917C79.0488 7.56202 78.7502 6.81513 78.7502 5.96901C78.7502 5.12289 79.0488 4.376 79.6956 3.72923C80.2927 3.13207 81.0396 2.8335 81.985 2.8335C82.9303 2.8335 83.6772 3.13207 84.3249 3.67962C84.922 4.27677 85.2206 5.02366 85.2206 5.86978C85.2206 6.76551 84.922 7.5124 84.3249 8.10956C83.6781 8.75633 82.9312 9.05491 81.985 9.05491Z" fill="white" />
                        <path id="Vector_38" d="M126.484 35.5848L126.434 15.1274L114.289 35.5848H111.103L98.9578 15.2762V35.5352L94.1797 35.5848V5.66943H98.2614L112.796 30.1591L127.131 5.66943H131.213L131.263 35.5352L126.485 35.5848H126.484Z" fill="white" />
                        <path id="Vector_39" d="M164.113 35.5353C154.606 35.585 160.231 35.5353 157.942 35.5353C159.385 34.59 160.48 32.9474 160.48 31.3047C160.48 30.1104 160.082 29.1146 159.335 28.3677C158.539 27.6208 157.593 27.0733 156.449 26.6754C155.304 26.2776 153.661 25.8293 151.571 25.3314C148.932 24.6846 146.842 24.0866 145.249 23.4398C143.606 22.793 142.262 21.8468 141.118 20.5028C139.973 19.2083 139.376 17.4665 139.376 15.2267C139.376 13.3847 139.874 11.6925 140.869 10.1996C141.814 8.70671 143.308 7.51151 145.299 6.61577C147.289 5.72004 149.778 5.27173 152.715 5.27173C154.756 5.27173 156.797 5.52069 158.788 6.06823C160.779 6.61577 162.471 7.36266 163.915 8.35763L162.272 12.3393C160.829 11.3939 159.236 10.6966 157.593 10.1987C155.951 9.7504 154.308 9.50144 152.715 9.50144C149.878 9.50144 147.787 9.99937 146.394 11.0448C144.95 12.0903 144.253 13.3847 144.253 15.0265C144.253 16.2208 144.651 17.2166 145.447 17.913C146.244 18.6599 147.24 19.2571 148.434 19.6549C149.579 20.1032 151.172 20.5506 153.212 20.9989C155.851 21.6457 157.941 22.2437 159.534 22.8905C161.127 23.5373 162.52 24.4835 163.665 25.7771C164.76 27.0715 165.357 28.8133 165.357 31.0035C165.357 32.8455 165.108 34.0398 164.113 35.5327L164.113 35.5353Z" fill="white" />
                    </g>
                </g>
            </svg>

        )
}
