import React, { useCallback, useState } from "react";
import { Box, Button, Typography, Container, useTheme } from "@mui/material";
import { Logo } from "./components/Logo";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import FeatureTile from "./components/FeatureTile";
import CollectionsRow from "./components/CollectionsRow";
import CtaCard from "./components/CtaCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import LivestreamsRow from "./components/LivestreamsRow";
import Map from "./components/Map";

const App = (props: any) => {
  const [count, setCount] = useState(0);
  const increment = useCallback(() => {
    setCount((count) => count + 1);
  }, [count]);

  const mobileMode = useMediaQuery("(max-width:600px)");
  const mediumMode = useMediaQuery("(max-width:900px)");
  const largeMode = useMediaQuery("(max-width:1280px)");

  const theme = useTheme();

  function fontScaler(num: number) {
    if (mobileMode) {
      return num / 4;
    }
    if (mediumMode) {
      return num / 3;
    }
    if (largeMode) {
      return num / 1.5;
    }
    return num;
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflowX: "none",
        position: "relative",
      }}
      pt={4}
      overflow="clip"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: -10,
          width: mobileMode ? "600px" : "1000px",
          height: mobileMode ? "600px" : "1000px",
          transform: "translate(+20%, -20%)",
          background:
            "radial-gradient(circle, rgba(59,56,206,0.7) 0%, rgba(0,0,0,0) 70%)",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -10,
          opacity: mediumMode ? 0 : 1,
          width: "45vw",
          height: "45vw",
          transform: "translate(-20%, -30%)",
          background:
            "radial-gradient(circle, rgba(81,196,221,0.6) 0%, rgba(38,36,163,0.3) 40%, rgba(0,0,0,0)60%)",
        }}
      />
      <Container maxWidth="lg" sx={{ zIndex: 10 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: mobileMode ? "column-reverse" : "row",
            alignItems: "center",
          }}
        >
          <Logo />
          {!mobileMode && (
            <Button
              variant="outlined"
              startIcon={<ArrowCircleDownIcon />}
              sx={{ paddingX: 3 }}
            >
              Get the app
            </Button>
          )}
        </Box>
        <Box
          display="flex"
          pb={8}
          pt={mobileMode ? 4 : 8}
          sx={{ position: "relative" }}
        >
          <Box width={mobileMode ? "100%" : "60%"} zIndex={20}>
            <Typography variant="h1">
              Live 360 virtual medical training
            </Typography>
            <Typography variant="h4" mt={2}>
              Extended reality medical training resources through Virtual
              Reality, Augmented Reality and the Metaverse.
            </Typography>

            <Button
              variant="contained"
              startIcon={<ArrowCircleDownIcon />}
              sx={{ mt: 4 }}
            >
              Get the app
            </Button>
          </Box>
          <img
            src="vrims-phone-title.png"
            style={{
              position: "absolute",
              width: "25rem",
              top: 0,
              right: 0,
              filter: mobileMode ? "brightness(0.5)" : undefined,
              transform: mobileMode ? "translateX(35%)" : undefined,
            }}
          />
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ zIndex: 10 }}>
        <Box
          sx={{
            display: "flex",
            gap: 6,
            justifyContent: "space-between",
            flexDirection: mobileMode ? "column" : "row",
            width: "100%",
          }}
        >
          <FeatureTile
            title="360 VR Training videos"
            text="Unlock access to a growing collection of highly detailed training videos"
            variant={1}
          />
          <FeatureTile
            title="Live streaming events"
            text="Access live 360 training events"
            variant={2}
          />
        </Box>
      </Container>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          my: 10,
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <CollectionsRow />
      </Box>
      <Container maxWidth="lg">
        <CtaCard />
      </Container>

      {/**If no livestreams are upcoming, or if the server is uncontactable, livestreamsRow will not output */}
      <LivestreamsRow />

      <Box
        mt={12}
        position="relative"
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box>
          <Map />
        </Box>
        <Box
          borderRadius={999}
          position="absolute"
          zIndex={10}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            aspectRatio: 1,
            width: mobileMode ? "30%" : "20%",
            top: mobileMode ? "2%" : "10%",
            left: "5%",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Typography variant="h2">6000⁺</Typography>
          <Typography variant="h6">participants</Typography>
        </Box>

        <Box
          borderRadius={999}
          position="absolute"
          zIndex={10}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            aspectRatio: 1,
            width: mobileMode ? "30%" : "15%",
            top: mobileMode ? "5%" : "20%",
            right: "5%",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Typography variant="h2">101⁺</Typography>
          <Typography variant="h6">countries</Typography>
        </Box>

        <Box
          position="absolute"
          top={"35%"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontWeight={700}
            fontSize={fontScaler(100)}
            textAlign="center"
            sx={{ width: "90%" }}
          >
            Our global reach
          </Typography>
        </Box>
        <Box
          position="absolute"
          bottom={"8%"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
        >
          <Logo size={120} />

          <Box
            display="flex"
            flexDirection={"column"}
            alignItems="center"
            justifyContent={"center"}
            pt={6}
            sx={{ opacity: 0.8 }}
          >
            <Typography variant="subtitle1">
              © 2023 by VRiMS. Company Number 14573800
            </Typography>
            <Typography variant="subtitle1">Registered in the UK</Typography>
            <Typography variant="subtitle1">
              47 Hassocks Road Hustpeirpoint BN69QL
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default App;
